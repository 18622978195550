import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/Home';
import ScheduleDemoPage from './components/ScheduleDemo';
import ThankYou from './components/ThankYou'

function App() {
  return (
    <Router>
      <div className="App">
      <Header/>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/demo" element={<ScheduleDemoPage />} />
          <Route path="/thanks" element={<ThankYou />} />
        </Routes>
      <Footer/>
      </div>
    </Router>
  );
}

export default App;
