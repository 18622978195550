import React from 'react';

const BlogPost = ({ title, description, link }) => (
  <div className="blog-post-card">
    <h3>{title}</h3>
    <p>{description}</p>
    <a href={link} target="_blank" rel="noopener noreferrer">Read on Substack</a>
  </div>
);

const BlogSection = () => {
  const blogPosts = [
    {
      title: "Analyzing the KnowBe4 Deepfake Hiring Scam",
      description: "How IdentifAI Could Have Prevented This AI-Powered Identity Fraud Attempt",
      link: "https://identifai.substack.com/p/analyzing-the-knowbe4-deepfake-hiring"
    },
    {
      title: "Live Deepfake Attack Strikes Hong Kong Once Again",
      description: "A UK-based multinational firm's Hong Kong office defrauded of HK$4M",
      link: "https://identifai.substack.com/p/live-deepfake-attack-strikes-hong"
    },
    {
      title: "The New Threat on the Block for Enterprise Cybersecurity",
      description: "Exploring emerging cybersecurity challenges for enterprises",
      link: "https://identifai.substack.com/p/the-new-threat-on-the-block-for-enterprise"
    },
    {
      title: "Current Deepfake Trends and Their Future Implications",
      description: "An Overview of the Most Prominent Deepfake Attacks and Their Impact",
      link: "https://identifai.substack.com/p/current-deepfake-trends-and-their"
    },
    {
      title: "Our Approach to Preventing Deepfake Scams: The Armstrong Incident",
      description: "How IdentifAI Could Have Protected a CEO Against a Deepfake Attack",
      link: "https://identifai.substack.com/p/our-approach-to-preventing-deepfake"
    },
    {
      title: "Coinbase CEO Deepfake Plagues Youtube",
      description: "IdentifAI Unmasks Deepfake in Widespread YouTube Ad Crypto Scam",
      link: "https://identifai.substack.com/p/coinbase-ceo-deepfake-plagues-youtube"
    }
  ];

  return (
    <section id="blog" className="blog-section">
      <h2>Our Latest Insights</h2>
      <div className="blog-posts-container">
        <div className="blog-posts">
          {blogPosts.map((post, index) => (
            <BlogPost key={index} {...post} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default BlogSection;
