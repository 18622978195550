import React from 'react';
import { Link } from 'react-router-dom';
import { FaLinkedin, FaTwitter } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">IdentifAI</div>
        <div className="footer-links">
          <a href="#product">{t('product')}</a>
          <a href="#company">{t('company')}</a>
          <a href="#testimonials">{t('customers')}</a>
          <a href="#blog">{t('blog')}</a>
          <a href="#contact">{t('contact')}</a>
        </div>
        <div className="social-links">
          <a href="https://www.linkedin.com/company/identifai-io" target="_blank" rel="noopener noreferrer" aria-label="IdentifAI LinkedIn"><FaLinkedin /></a>
          <a href="https://x.com/identifAI_" target="_blank" rel="noopener noreferrer" aria-label="IdentifAI Twitter"><FaTwitter /></a>
        </div>
      </div>
      <div className="footer-bottom">
        <p>{t('copyright', { year: new Date().getFullYear() })}</p>
      </div>
    </footer>
  );
}

export default Footer;
